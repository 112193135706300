import React, {ReactElement} from "react";

export type Tab<T> = {
  value: T;
  label: string;
  icon: ReactElement;
};

interface IProps<T> {
  tabs: Tab<T>[];
  value: T;
  onChange: (value: T) => void;
}

export const Tabs = <T,>({tabs, value, onChange}: IProps<T>): ReactElement =>
	<div style={{display: "inline-block"}}>
		{tabs.map((tab, i) => <div
			key={"tab_" + i}
			style={{
				display: "inline-block",
				opacity: tab.value === value ? 1 : 0.6,
				cursor: "pointer",
				paddingBottom: 15,
				paddingRight: 15,
				marginBottom: 15,
				borderBottom: tab.value === value ? "solid #d1d1d194 3px" : "none"
			}}
			onClick={() => onChange(tab.value)}
		>
			{React.cloneElement(tab.icon, {
				style: {padding: "10px  5px 0 0"}
			})}
			{tab.label}
		</div>
		)}
	</div>;



